export interface Notification {
  message: string
  timer: NodeJS.Timeout
}

export interface NotificationsResult {
  notification: Notification
  addNotification: (type: string) => void
  clearNotification: (type: string) => void
  clearAllNotifications: () => void
}

export const NOTIFICATION_TIMEOUT = 2000

export const NOTIFICATION_NO_FACE_DETECTED = 'Nenhuma face detectada'
export const NOTIFICATION_INTERFERENCE_WARNING = 'Leitura ruim, por favor, tente novamente em melhores condições'
export const NOTIFICATION_FACE_ORIENT_WARNING = 'Por favor, olhe diretamente para frente'
export const NOTIFICATION_FACE_SIZE_WARNING = 'Por favor, aproxime-se mais da câmera'