import { useEffect, useState } from 'react'
import { ImageQualityFlags } from 'rppg/lib/RPPGTracker.types'
import './ImageQuality.scss'
import 'react-circular-progressbar/dist/styles.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

type Props = {
  imageQualityFlags: ImageQualityFlags
}

const CustomCircularProgressbar = ({ percentage }: { percentage: number }) => {
  let textColor = ''
  let pathColor = ''
  let trailColor = ''

  if (percentage <= 20) {
      textColor = 'black'
      pathColor = 'red'
      trailColor = 'lightgray'
  } else if (percentage <= 50) {
      textColor = 'black'
      pathColor = 'yellow'
      trailColor = 'lightgray'
  } else if (percentage >= 75) {
      textColor = 'black'
      pathColor = 'green'
      trailColor = 'lightgray'
  } else {
      // Defina cores padrão caso a porcentagem não atenda a nenhum dos critérios acima
      textColor = 'black'
      pathColor = 'gray'
      trailColor = 'lightgray'
  }

  return (
      <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
              textColor: textColor,
              pathColor: pathColor,
              trailColor: trailColor,
          })}
      />
  )
}


export const ImageQuality: React.FC<Props> = ({ imageQualityFlags }) => {
  const [imageQuality, setImageQuality] = useState<boolean[]>([])
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    const {
      brightColorFlag,
      illumChangeFlag,
      noiseFlag,
      sharpFlag,
    } = imageQualityFlags

    const value = Object.values({
      brightColorFlag,
      illumChangeFlag,
      noiseFlag,
      sharpFlag,
    }).sort((a, b) => +b - +a)

    const countTrue = imageQuality.filter(value => value === true).length
    const totalCount = imageQuality.length

    setPercent((countTrue / totalCount) * 100)
    setImageQuality(value)

  }, [imageQualityFlags])


  return (
    <div className="image-quality-container">
      <CustomCircularProgressbar percentage={percent} />

      {/* {imageQuality.map((value, key) =>
        // <svg
        //   key={key}
        //   className={`image-quality ${value ? 'active' : ''}`}
        //   xmlns="http://www.w3.org/2000/svg"
        //   width="255"
        //   height="240"
        //   viewBox="0 0 51 48">
        //   <path fill="currentColor" stroke="#000" d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
        // </svg>
        <CircularProgressbar value={percent} text='' />

      )} */}
    </div>
  )
}
