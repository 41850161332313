import { Button } from '@fluentui/react-northstar'
import { Warning } from 'components'
import { useHistory } from 'react-router-dom'
import './NotSupported.scss'

export function NotSupported() {
  const history = useHistory()

  const tryAgainButtonHandler = () => {
    history.push('/capture')
  }

  return (
    <div className="not-supported-container">
        <Warning size="big">
        Este dispositivo não é suportado devido a uma baixa taxa de quadros da câmera. Por favor, utilize este aplicativo em um dispositivo diferente.
      </Warning>

      <Button primary onClick={tryAgainButtonHandler} content="Voltar" />
    </div>
  )
}
