import { Button } from '@fluentui/react-northstar'
import { useHistory } from 'react-router-dom'
import './BadConditions.scss'

export function BadConditions() {
  const history = useHistory()

  const tryAgainButtonHandler = () => {
    history.push('/capture')
  }

  return (
    <div className="bad-conditions-container">
       <div className='logo'>
        <img
          className="item-img"
          src={require('assets/images/logo-medicoaqui.png').default}
          alt="calculating" />
      </div>
      <div className="title-error">Ocorreu um erro</div>
      <div className="message-container">
        <div className="title">Incapaz de fazer leituras</div>
        <div className="content">
        Esse erro ocorre quando há muito movimento no quadro da câmera ou as condições de iluminação estão instáveis. 
        Por favor, tente novamente em condições mais estáveis e certifique-se de que seu rosto esteja iluminado de forma uniforme.
        </div>
      </div>

      <div className='button-container'>
        <Button onClick={tryAgainButtonHandler} primary content="Tentar novamente" />
      </div>

    </div>
  )
}
