export const serviceMessages = [{
  title: 'Posicione-se',
  description: 'Por favor, certifique-se de que seu rosto ocupa a maior parte da visão da câmera acima',
}, {
  title: 'Calibrando',
  description: 'Por favor, mantenha-se imóvel',
}]

export const calculatingMessages = [{
  title: 'Extraindo seu ritmo cardíaco',
  description: 'Certifique-se de que seu rosto esteja bem iluminado',
}, {
  title: 'Analisando sua fisiologia',
  description: 'Isso não vai demorar muito',
}, {
  title: 'Aguente firme',
  description: 'Os círculos abaixo indicarão quando tivermos valores',
}, {
  title: 'Expandindo a busca biométrica',
  description: 'Estamos buscando mais métricas fisiológicas',
}]

export const intervalDelay = 5000
