import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@fluentui/react-northstar'
import classNames from 'classnames'
import useFaceMesh from 'hooks/useFaceMesh'
import useRPPG from 'hooks/useRPPG'
import useNotification from 'hooks/useNotification'
import {
  LoadingScreen,
  CheckFps,
  ImageQuality,
  Notification,
  Info,
  TextMessage,
  ProgressType,
} from 'components'
import {
  NOTIFICATION_FACE_ORIENT_WARNING,
  NOTIFICATION_FACE_SIZE_WARNING,
  NOTIFICATION_INTERFERENCE_WARNING,
  NOTIFICATION_NO_FACE_DETECTED,
} from 'helpers/notification'
import './Capture.scss'
import { Debugger } from 'pages/Debugger/Debugger'
import RPPGTracker from 'rppg/lib/RPPGTracker'

export function Capture() {
  const history = useHistory()
  const videoElement = useRef<HTMLVideoElement>(null)
  const canvasElement = useRef<HTMLCanvasElement>(null)
  const [debug] = useState(false)

  const [loading, setLoading] = useState(false)
  const [finished, setFinished] = useState(false)
  const [conditions, setConditions] = useState({
    light: false,
    face: false,
    faceSize: false,
    faceOrient: false,
  })
  const [conditionsOk, setConditionsOk] = useState(false)


  const [size, setSize] = useState<{ width: number, height: number }>(
    { width: 0, height: 0 }
  )

  const {
    message,
    addNotification,
    clearAllNotifications,
  } = useNotification()

  const processingFaceMesh = useRef(false)

  const [useFrontCamera, setUseFrontCamera] = useState(true)

  const onUnsupportedDeviceCb = () => {
    stopHandler()
    history.push('/capture/not-supported')
  }

  const onCalculationEndedCb = async () => {
    if(processing){
      try {
        stopHandler()
        setLoading(true)
    closeCamera()
    cameraInstance?.stop()
    history.push('/capture/results', {
      rppgData,
      isAllDataCalculated,
    })
      } catch (error) {
        window.console.log(error)
      } finally {
        setLoading(false)
      }
    }
 
  }

  const onFaceDetectionChange = (faceDetected: boolean) => {
    if (processing && !faceDetected) {
      addNotification(NOTIFICATION_NO_FACE_DETECTED)
      setConditions(conditions => ({
        ...conditions,
        face: false,
      }))
    } else {
      setConditions(conditions => ({
        ...conditions,
        face: true,
      }))
    }
  }

  const onInterferenceWarningCb = () => {
    addNotification(NOTIFICATION_INTERFERENCE_WARNING)
    setTimeout(() => {
      stopHandler()
      history.push('/capture/bad-conditions')
    }, 3000)
  }

  const onFaceOrientWarningCb = () =>
    processing && addNotification(NOTIFICATION_FACE_ORIENT_WARNING)

  const onFaceSizeWarningCb = () =>
    processing && addNotification(NOTIFICATION_FACE_SIZE_WARNING)

  // Facemesh init
  const {
    cameraInstance,
    faceDetected,
    faceSizeStatus,
  } = useFaceMesh({
    videoElement,
    canvasElement,
    processing: processingFaceMesh,
    onFaceDetectionChange,
  })

  // Rppg init
  const {
    rppgData,
    ready,
    rppgInstance,
    isAllDataCalculated,
    imageQualityFlags,
    progressType,
    processing,
    checkFps,
    start,
    stop,
    closeCamera,
  } = useRPPG({
    videoElement,
    onUnsupportedDeviceCb,
    onAllDataCalculatedCb: onCalculationEndedCb,
    onCalculationEndedCb,
    onInterferenceWarningCb,
    onFaceOrientWarningCb,
    onFaceSizeWarningCb,
  })


  useEffect(() => {
    if (!rppgInstance || !ready) {
      return
    }
    console.log('useEffect - rppg - initialized')
    // @ts-ignore
    const { width, height } = rppgInstance.rppgCamera
    setSize({ width, height })
  }, [ready, rppgInstance])

  useEffect(() => {
    processingFaceMesh.current = processing
  }, [processing])

  const startHandler = () => {
    start()
  }

  const stopHandler = () => {
    setFinished(true)
    clearAllNotifications()
    stop()
  }

  const startButtonHandler = () => {
    if (processing) {
      stopHandler()
    } else {
      startHandler()
    }
  }


  React.useEffect(() =>
  setConditionsOk(Object.values(conditions).every(Boolean))
, [conditions])

  React.useEffect(() => {

     /**
     * @description Dados gerais
     */

     console.log('Dados gerais:', rppgData)


    /**
     * @description Progresso da medição
     */

    console.log('Progresso:', rppgData.measurementProgress)


     /**
     * @description Pressão sanguinêa
     */
    
     console.log('Pressão sanguinêa:', rppgData.measurementData.bloodPressureStatus)

     /**
     * @description Batimentos cardíacos
     */
    
       console.log('Batimentos cardíacos:', rppgData.measurementData.bpm)

    /**
     * @description Oxigenio
     */
    
       console.log('Oxigenio:', rppgData.measurementData.oxygen)

      /**
     * @description Respiração
     */
    
      console.log('Respiração:', rppgData.measurementData.rr)

        /**
     * @description Nível de estressse
     */
    
        console.log('Nível de estressse:', rppgData.measurementData.stressStatus)
  }, [rppgData])




  return (
    <div className="measurement-container">
      {/* isAllDataCalculated: {String(isAllDataCalculated)}, 
      fps: {JSON.stringify(fps)}<br/>
      ready: {String(ready)},
      faceDetected: {String(faceDetected)}, */}

      {!ready && <LoadingScreen />}
      <div className='logo'>
        <img
          className="item-img"
          src={require('assets/images/logo-medicoaqui.png').default}
          alt="calculating" />
      </div>
      <div className="measurement-wrapper">

        <div className='video-content'>
          <div className="video-container">
            <div className="video-wrapper">
              <video
                ref={videoElement}
                autoPlay
                playsInline
                muted
                className={classNames({
                  video: true,
                  horizontal: size.width > size.height,
                  vertical: size.width <= size.height,
                  invert: useFrontCamera,
                })}>
              </video>
              <canvas
                ref={canvasElement}
                className="canvas"
              ></canvas>
            </div>
          </div>
        </div>
        {/* {processing && <ImageQuality imageQualityFlags={imageQualityFlags} />} */}
        <div className='imageQuality-container'>
          {processing && <ImageQuality imageQualityFlags={imageQualityFlags} />}
        </div>
        <div className="start-block-wrapper">
          <div className="message-container">
            {/* {message ? ( */}
            {message ? (
              <Notification message={message} />
            ) : (
              <>
                <TextMessage progressType={progressType} />

                {checkFps && <CheckFps />}

              </>
            )}
            {/* {processing && progressType !== ProgressType.CALIBRATING && ( */}
          </div>


          {processing && progressType !== ProgressType.CALIBRATING && (
            <div className="info-content">
              <Info rppgData={rppgData} />
            </div>
          )}


        </div>
        <div className='button-start'>
          <Button
            primary
            // fluids
            content={processing ? 'Parar leitura' : 'Iniciar leitura'}
            disabled={!ready}
            loading={!ready}
            onClick={startButtonHandler}
          />
        </div>
      </div>


     {debug && <Debugger
          imageQualityFlags={imageQualityFlags}
          fps={rppgData.fps}
          faceDetected={faceDetected}
          faceSizeStatus={faceSizeStatus}
          processing={processing}
          loading={loading}
          ready={ready}
          progressType={progressType}
          finished={finished}
          conditions={conditions}
          conditionsOk={conditionsOk}
        />}
    </div>
  )
}
