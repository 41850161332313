import { CSSProperties } from 'react'

export const loadingScreenMessages = [
  'Carregando módulos...',
  'Com isso, seu aplicativo se transformará em uma ferramenta de monitoramento remoto...',
  'Usando inteligência artificial, seremos capazes de entender sua fisiologia...',
  'Por favor, aguarde. Isso não vai demorar muito...',
]

export const loadingScreenDelay = 8000

export const spinnerCssOverride: CSSProperties = {
  borderWidth: '4px',
  borderColor: 'rgba(255, 255, 255, 0.7) rgba(255, 255, 255, 0.7) rgba(255, 255, 255, 0.063)',
}

export const spinnerSpeedMultiplier = 0.75

export const spinnerSize = 64

